<template>
  <div class="users-content">
    <h1>Users</h1>
    <div class="users">
      <dx-data-grid 
        id="userGrid"
        ref="usersDataGrid"
        :data-source="userStore" 
        @context-menu-preparing="prepairingContextMenu"
        no-data-text="User list is empty"
        :allow-column-reordering="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :show-borders="true"
        @row-dbl-click="gridRowDoubleClick"
        @toolbar-preparing="onToolbarPreparing($event)">
          <dx-editing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            mode="popup"/>
          <dx-column data-field="email" data-type="string" caption="Email" :visible="true"/>
          <dx-column data-field="userName" data-type="string" caption="User Name" :visible="true"/>
          <dx-column data-field="firstName" data-type="string" caption="First Name" :visible="true"/>
          <dx-column data-field="secondName" data-type="string" caption="Second Name" :visible="true"/>
          <dx-column data-field="lastName" data-type="string" caption="Last Name" :visible="true"/>
          <dx-column data-field="password" data-type="string" caption="Password" :visible="false"/>
      </dx-data-grid>
    </div>
    <h1>Roles</h1>
    <div class="roles">
      <dx-data-grid 
        id="rolesGrid"
        ref="rolesDataGrid"
        :data-source="roleStore" 
        @context-menu-preparing="prepairingContextMenu"
        no-data-text="Roles list is empty"
        :allow-column-reordering="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :show-borders="true">
          <dx-editing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            mode="row" />
          <dx-column data-field="id" data-type="string" caption="ID" :visible="false"/>
          <dx-column data-field="name" data-type="string" caption="Role Name" :visible="true"/>
      </dx-data-grid>      
    </div>
  </div>
</template>

<script>
export default {
  name:"users",
  setup(){
    const usersDataGrid = ref(null);
    const rolesDataGrid = ref(null);

    const prepairingContextMenu = (e) => {
      const { element, target } = e; 

      if(element.id == "userGrid" && target == "content"){
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Create user',
            onItemClick: async () => {
                // await createLicense(e.row.data.id);
            }
        });
      }
      
      if(element.id == "rolesGrid" && target == "content"){
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Create role',
            onItemClick: async () => {
                // await createLicense(e.row.data.id);
            }
        });
      }
    };

    const onToolbarPreparing = (e) => {
      const { element } = e;
      
      if(element.id == 'userGrid') {
          e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: "refresh",
                  onClick: function () {
                    usersDataGrid.value.instance.refresh()
                  }
              }
          },)
      }
    };

    const gridRowDoubleClick = (row) => {
      const { element } = row;

      if(element.id == 'userGrid') {
        usersDataGrid.value.instance.editRow(row.rowIndex);
      }
    };

    return {usersDataGrid, rolesDataGrid, prepairingContextMenu, onToolbarPreparing, gridRowDoubleClick}
  },
  data() {
      return {
          userStore: UserStore,
          roleStore: RolesStore,
      }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing, 
    // DxFilterRow,
    // DxSearchPanel,
    // DxGroupPanel,
    // DxPager,
    // DxPaging,
    // DxRemoteOperations,
    // DxColumnChooser,
    // DxLoadPanel
},
}

  import { 
      DxDataGrid, 
      DxColumn,
      DxEditing,   
      // DxFilterRow,
      // DxSearchPanel,
      // DxGroupPanel,
      // DxPager,
      // DxPaging,
      // DxRemoteOperations,
      // DxColumnChooser,
      // DxLoadPanel,
      // 
  } from 'devextreme-vue/data-grid';

  import { RolesStore, UserStore } from '@/services/user-service'

  import { 
      ref,
  } 
  from 'vue'

</script>

<style lang="scss" scoped>
.users-content{
  display: flex;
  flex-direction: column;
}
</style>